const attachModal = (selector) => {
  let isModalOpen = false;

  const updatesModal = document.querySelector(selector);
  if (!updatesModal) {
    return;
  }

  const openModal = (event) => {
    event.stopPropagation();
    updatesModal.classList.add('is-open');
    isModalOpen = true;
  };
  const closeModal = (event) => {
    event.stopPropagation();
    updatesModal.classList.remove('is-open');
    isModalOpen = false;
  };

  const closeParent = (event) => {
    event.stopPropagation();
    if (event.target.closest(`${selector}-content`)) {
      return;
    }
    if (isModalOpen) {
      updatesModal.classList.remove('is-open');
      isModalOpen = false;
    }
  };

  const modalOpeners = document.querySelectorAll(`${selector}-open`);
  modalOpeners.forEach((el) => {
    el.addEventListener('click', openModal);
  });

  const modalClosers = document.querySelectorAll(`${selector}-close`);
  modalClosers.forEach((el) => {
    el.addEventListener('click', closeModal);
  });

  updatesModal.addEventListener('click', closeParent);
};

export default attachModal;
