import eventDelegator from './delegation-helper';

const orderSuppliesModal = (selector) => {
  let formModal = null;

  const stepperClick = (event) => {
    //

    const { target: stepper } = event;

    const increment = stepper.innerText === '+' ? 1 : -1;
    const input = stepper.parentNode.querySelector('input[type=number]');
    if (!input) {
      return;
    }

    let v = parseInt(input.value, 10);
    v += increment;
    v = v >= 0 ? v : 0;
    input.value = v;
  };

  const closeModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (formModal) {
      document.body.removeChild(formModal);
      formModal = null;
    }
  };

  const closeParent = (event) => {
    if (event.target.closest(`${selector}-content`)) {
      return;
    }
    closeModal(event);
  };

  const submitForm = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const formData = new FormData(event.target);

    (async () => {
      const result = await fetch('/portal/supplies/form', {
        method: 'POST',
        body: formData,
      });

      const formModalInner = await result.text();
      formModal.innerHTML = formModalInner;

      if (formModal.querySelector('.js-supplies-ordered-success')) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'supplies-ordered',
        });
      }
    })();
  };

  const showHideForm = (event) => {
    const formList = document.querySelector('ul.js-form-rest');
    formList.style.display = event.target.checked ? 'block' : 'none';
  };

  const openModal = async (event) => {
    event.stopPropagation();
    event.preventDefault();

    formModal = document.createElement('div');
    formModal.className = 'modal--solo supplies-modal js-supplies-modal';
    document.body.appendChild(formModal);

    const result = await fetch('/portal/supplies/form', {
      credentials: 'include',
    });

    const formModalInner = await result.text();
    formModal.innerHTML = formModalInner;

    eventDelegator(formModal, 'click', `${selector}-close`, closeModal);
    eventDelegator(formModal, 'click', `${selector}`, closeParent);
    eventDelegator(formModal, 'submit', 'form[name="supplies"]', submitForm);
    eventDelegator(formModal, 'click', 'button.button_stepper', stepperClick);
    eventDelegator(formModal, 'change', 'input.js-different-address', showHideForm);
  };

  const modalOpeners = document.querySelectorAll(`${selector}-open`);
  modalOpeners.forEach((el) => {
    el.addEventListener('click', openModal);
  });
};

export default orderSuppliesModal;
