import '../less/styles.less';
import attachModal from './helpers/basic-modal-helper';
import reorderModalHelper from './helpers/form-modal-helper';
import attachToggleable from './helpers/toggle-helper';
import showOrHideTour from './helpers/tour-helper';

const ready = () => {
  const dashboardInterval = document.querySelector('.dashboard-blocks__filter select');
  if (dashboardInterval) {
    dashboardInterval.addEventListener('change', (ev) => {
      window.location = `/portal?interval=${ev.target.value}`;
    });
  }

  const toasters = document.querySelectorAll('.is-active.js_toast_success');
  toasters.forEach((toaster) => {
    setTimeout(() => {
      toaster.className = toaster.className.replace('is-active', '');
      setTimeout(() => {
        toaster.parentElement.removeChild(toaster);
      }, 1000);
    }, 4000);
  });

  attachModal('.js-updates-modal');
  attachModal('.js-terms-modal');
  attachToggleable('.js-toggleable');
  reorderModalHelper('.js-supplies-modal');
};

if (document.readyState !== 'loading') {
  ready();
} else {
  document.addEventListener('DOMContentLoaded', ready);
}

//Tour modal
if (document.querySelector('.js-tour-modal')) {
  const tourModal = document.querySelector('.js-tour-modal');
  const tourModalClose = document.querySelectorAll('.js-tour-modal-close');
  const hideTourCheckbox = document.querySelector('.js-hide-tour');

  tourModalClose.forEach((el) => {
    el.addEventListener('click', function() {
      tourModal.classList.remove('is-open');
    });
  });

  hideTourCheckbox.addEventListener('change', function(el) {
    showOrHideTour(el.target.checked)
  });
}
