const eventDelegator = (node, eventName, selector, callback) => {
  node.addEventListener(eventName, (event) => {
    const { target = null } = event;
    if (target) {
      const closest = target.closest(selector);
      if (closest && node.contains(closest)) {
        callback(event, closest);
      }
    }
  });
};

export default eventDelegator;
