const attachToggleable = (selector, activeClass = 'is-active') => {
  const togglables = [];

  document.querySelectorAll(selector).forEach((n) => {
    n.addEventListener('click', (event) => {
      event.preventDefault();
      event.stopPropagation();
      n.classList.toggle(activeClass);
    });
    togglables.push(n);
  });

  document.body.addEventListener('click', () => {
    togglables.forEach((n) => {
      n.classList.remove('is-active');
    });
  });
};

export default attachToggleable;
