const showOrHideTour = async (hideTour) => {
  const endpoint = `/portal/customer/tour/${hideTour}`;

  const response = await fetch(endpoint, {
    headers: {
      accept: 'application/json',
    },
    method: 'GET',
    credentials: 'same-origin',
  });
};

export default showOrHideTour;
